<template>
  <div class="courseAdjustmentManage">
    <div class="flex">
      <div class="header-button">
        <el-button type="success"
                   @click="add">添加代课</el-button>
        <el-button type="primary"
                   @click="downFile">导 出</el-button>
      </div>
      <div>
        <el-input placeholder="请输入教师名称"
                  class="header-query"
                  v-model="queryTeacherName"></el-input>

        <el-date-picker v-model="queryTime"
                        type="daterange"
                        class="header-query-frame"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>

        <!-- <el-date-picker v-model="queryStartTime"
                        type="date"
                        class="header-query"
                        placeholder="开始时间"
                        value-format="yyyy-MM-dd" />
        <el-date-picker v-model="queryEndTime"
                        type="date"
                        class="header-query"
                        placeholder="结束时间"
                        value-format="yyyy-MM-dd" /> -->
        <el-button class="picker"
                   type="primary"
                   @click="loadSubstituteCourse(1)">查 询</el-button>
      </div>
    </div>
    <el-table :data="courseAdjustTable"
              border>
      <el-table-column label="序号"
                       type="index"
                       align="center"></el-table-column>
      <el-table-column label="类别"
                       align="center"><template slot-scope="scope">{{scope.row.type===0?'主教':'辅教'}}</template></el-table-column>
      <el-table-column label="原教学计划"
                       align="center">
        <el-table-column label="被代课日期"
                         prop="leave_time"
                         align="center"></el-table-column>
        <el-table-column label="被代课班级"
                         prop="cname"
                         align="center"></el-table-column>
        <el-table-column label="被代课节次"
                         prop="sort_hour"
                         align="center"></el-table-column>
        <el-table-column label="被代课学科"
                         prop="course"
                         align="center"></el-table-column>
        <el-table-column label="被代课教师"
                         prop="mname"
                         align="center"></el-table-column>
      </el-table-column>
      <el-table-column label="代课方案"
                       align="center">
        <el-table-column label="代课教师"
                         prop="d_mname"
                         align="center"></el-table-column>
      </el-table-column>
      <el-table-column label="操作"
                       width="180px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="editAdjustment(scope.row)">{{scope.row.d_mname?'调 整':'编 辑'}}</el-button>
          <el-button type="danger"
                     @click="deleteAdjustment(scope.$index,scope.row.id)">删 除</el-button>
        </template>

      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="substituteVisible"
               width="800px"
               title="添加代课">
      <div class="substitute-content">
        <div class="class-selector">
          <class-selector v-model="queryClassId"
                          :initialValue="queryClassId"
                          :clearCascader="true"
                          @change="classChange" />
          <el-tooltip class="refresh-question"
                      effect="dark"
                      content="请先选择班级"
                      placement="right">
            <i class="el-icon-question" />
          </el-tooltip>
        </div>
        <table cellspacing="0"
               border="1"
               cellpadding="25"
               v-if="queryClassId!==0"
               class="table-box">
          <tr>
            <td rowspan="3">节课/星期</td>
          </tr>
          <tr>
            <td v-for="(date,index) in dateList"
                :key="index">
              <i class="el-icon-caret-left"
                 @click="clickLastWeek"
                 v-if="index===0"></i>
              {{date}}
              <i class="el-icon-caret-right"
                 @click="clickNextWeek"
                 v-if="index===4"></i>
            </td>
          </tr>
          <tr>
            <td>星期一</td>
            <td>星期二</td>
            <td>星期三</td>
            <td>星期四</td>
            <td>星期五</td>
          </tr>
          <tr v-for="(time,timeIndex) in timeList"
              :key="timeIndex">
            <td>{{time.section}}</td>
            <td v-for="(course,index) in time.course"
                :key="index"
                class="teacher-name"
                @click="getCurrentTimetable(course,timeIndex,index)"
                :tabindex="index"
                :colspan="time.course.length===1 ? '5' : '1'">
              <p v-for="(name,index) in (course.split('-'))"
                 :key="index">{{name}}</p>
            </td>
          </tr>
        </table>
      </div>
      <div slot="footer">
        <el-button @click="substituteVisible=false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="adjustmentVisible"
               width="600px"
               title="添加代课">
      <!-- 代课教师类型 -->
      <el-select v-model="teachingType"
                 clearable
                 @change="loadSubstituteTeacher"
                 placeholder="请选择教师类型">
        <el-option v-for="teachingType in teachingTypeList"
                   :key="teachingType.value"
                   :label="teachingType.name"
                   :value="teachingType.value"></el-option>
      </el-select>
      <div>
        <div class="teacher-header">代课教师名称</div>
        <div class="teacher">
          <div v-for="(teacher,index) in adjustmentTeacherList"
               :key="teacher.value">
            <div class="teacher-name"
                 @click="adjustmentTeacherCilck(teacher.value)"
                 :tabindex="index">{{teacher.label}}</div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="adjustmentVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="addAdjustmentTeacher">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="selectTeacherTypeVisible"
               title="确定被代课教师">
      <el-select v-model="substituteTeacher"
                 placeholder="请选择教师">
        <el-option v-for="(teaching,index) in teacherList"
                   :key="index"
                   :value="teaching"></el-option>
      </el-select>
      <!-- 被代课教师类型 -->
      <el-select v-model="substituteTeacherType"
                 placeholder="请选择教师类型">
        <el-option v-for="teachingType in teachingTypeList"
                   :key="teachingType.value"
                   :label="teachingType.name"
                   :value="teachingType.value"></el-option>
      </el-select>
      <div slot="footer">
        <el-button @click="selectTeacherTypeVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="determine">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadSubstituteCourse"></el-pagination>
  </div>
</template>

<script>
import ClassSelector from "@/components/selectors/classSelector";

export default {
  name: "courseAdjustmentManage",
  components: { ClassSelector },
  data() {
    return {
      courseAdjustTable: [],
      substituteVisible: false,
      page: { pageCount: 1, currentPage: 0 },
      adjustmentVisible: false,
      teachingType: "",
      adjustmentTeacherList: [],
      teachingTypeList: [
        { value: 0, name: "主教" },
        { value: 1, name: "辅教" },
      ],
      currentAdjustmentId: -1,
      currentLeaveTime: "",
      currentId: "",
      currentType: 0, //主教辅教
      selectAdjustmentTeacher: "",
      queryStartTime: "",
      queryEndTime: "",
      queryTeacherName: "",
      timeList: [
        { section: "第一节(8:30-8:45)", sectionIndex: "1", course: [] },
        {
          section: "(8:45-9:35)",
          sectionIndex: "7",
          course: ["大课间（阳光体育）"],
        },
        { section: "第二节(9:35-10:10)", sectionIndex: "2", course: [] },
        { section: "第三节(10:20-10:55)", sectionIndex: "3", course: [] },
        { section: "第四节(11:05-11:40)", sectionIndex: "4", course: [] },
        {
          section: "(11:40-14:40)",
          sectionIndex: "8",
          course: ["午餐午休"],
        },
        { section: "第五节(14:40-15:15)", sectionIndex: "5", course: [] },
        { section: "第六节(15:25-16:00)", sectionIndex: "6", course: [] },
      ],
      queryClassId: 0,
      dateList: [],
      currentFirstDate: "",
      selectTeacherTypeVisible: false,
      substituteTeacherType: "",
      substituteTeacher: "",
      teacherList: [],
      currentAdjustmentCourse: [],
      selectDay: "",
      currentSection: "",
      selectId: "",
      queryTime: "",
      userId: JSON.parse(sessionStorage.getItem("userInfo")).userid,
    };
  },
  created() {
    this.loadSubstituteCourse(1);
    this.setDate(new Date());
  },
  methods: {
    loadSubstituteCourse(page) {
      let data = { indexNo: page };
      // if (this.queryStartTime) {
      //   data.start_time = this.queryStartTime;
      // }
      // if (this.queryEndTime) {
      //   data.end_time = this.queryEndTime;
      // }
      if (this.queryTime) {
        data.start_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      if (this.queryTeacherName) {
        data.name = this.queryTeacherName;
      }
      this.$post("/base/queryDaiSchedule.do", data)
        .then((res) => {
          this.courseAdjustTable = res.data.rows;
          this.page.currentPage = res.data.indexNO;
          this.page.pageCount = res.data.pageCount;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadClassSchedule() {
      const that = this;
      this.$post("/base/queryCourseTable.do", {
        classid: this.queryClassId,
      })
        .then((res) => {
          res.data.forEach((day) => {
            that.timeList.forEach((time) => {
              if (day.day === time.sectionIndex) {
                time.course = day.value;
              }
            });
          });
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    add() {
      this.substituteVisible = true;
      this.queryClassId = 0;
    },
    determine() {
      console.log(this.substituteTeacher);
      this.$confirm("是否确认此操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/insertDaiSchedule.do", {
            mname: this.substituteTeacher,
            course: this.currentAdjustmentCourse[0],
            sort_hour: this.currentSection,
            schedule_id: this.selectId,
            leave_time: this.selectDay,
            classid: this.queryClassId,
            type: this.substituteTeacherType,
            // managerid: this.userId,暂时无法拿到ID，由后台查
          })
            .then((res) => {
              this.selectTeacherTypeVisible = false;
              this.substituteVisible = false;
              this.$message({
                type: "success",
                message: res.message,
              });
              this.loadSubstituteCourse(1);
            })
            .catch((err) => {
              this.selectTeacherTypeVisible = false;
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    editAdjustment(row) {
      this.adjustmentVisible = true;
      this.teachingType = "";
      this.currentAdjustmentId = row.schedule_id;
      this.currentLeaveTime = row.leave_time;
      this.currentId = row.id; //点击的那一条数据得id
      this.currentType = row.type;
      this.loadSubstituteTeacher();
    },
    loadSubstituteTeacher() {
      let data = {
        schedule_id: this.currentAdjustmentId,
        leave_time: this.currentLeaveTime,
      };
      if (this.teachingType === 0) {
        data.type = this.teachingType;
      } else if (this.teachingType === 1) {
        data.type = this.teachingType;
      }
      this.$post("/base/queryDaiTeacher.do", data)
        .then((res) => {
          this.adjustmentTeacherList = [];
          if (res.data.length !== 0) {
            res.data.forEach((row) => {
              let teacher = {};
              teacher.label = row.name;
              teacher.value = row.id;
              this.adjustmentTeacherList.push(teacher);
            });
          }
          //数组去重
          let obj = {};
          this.adjustmentTeacherList = this.adjustmentTeacherList.reduce(
            function (item, next) {
              obj[next.value]
                ? ""
                : (obj[next.value] = true && item.push(next));
              return item;
            },
            []
          );
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    //点击代课教师
    adjustmentTeacherCilck(teacherId) {
      this.selectAdjustmentTeacher = teacherId;
    },
    //点击班级课表获取当前班级节次和日期
    getCurrentTimetable(course, column, row) {
      this.teacherList = [];
      this.substituteTeacher = "";
      this.substituteTeacherType = "";
      //第几节课
      if (column === 0) {
        this.currentSection = column + 1;
      } else if (column === 2 || column === 3 || column === 3) {
        this.currentSection = column;
      } else if (column == 6 || column === 7) {
        this.currentSection = column - 1;
      }
      this.selectId = `${row + 1}${this.currentSection}`;
      this.selectDay = this.dateList[row];
      const currentCourse = course.split("-");
      for (let i = 0; i < currentCourse.length; i++) {
        if (i !== 0) {
          this.teacherList.push(currentCourse[i]);
        }
      }
      this.currentAdjustmentCourse = currentCourse;
      this.selectTeacherTypeVisible = true;
    },
    addAdjustmentTeacher() {
      let data = {
        id: this.currentId,
        d_managerid: this.selectAdjustmentTeacher,
      };
      this.$confirm("是否确认此操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/updateDaiSchedule.do", data)
            .then((res) => {
              this.adjustmentVisible = false;
              this.$message({
                type: "success",
                message: res.message,
              });
              this.loadSubstituteCourse(1);
            })
            .catch((err) => {
              this.adjustmentVisible = false;
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.adjustmentVisible = false;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    classChange(value) {
      this.queryClassId = value;
      this.loadClassSchedule();
    },
    deleteAdjustment(index, id) {
      this.$confirm("是否确认删除该数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteDaiSchedule.do", { id })
            .then((res) => {
              this.courseAdjustTable.splice(index, 1);
              this.$message({
                type: "success",
                message: res.message,
              });
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //设置日期
    setDate(date) {
      let clen = 7;
      let week = date.getDay() - 1;
      date = this.addDate(date, week * -1);
      this.currentFirstDate = new Date(date);
      for (let i = 0; i < clen; i++) {
        if (i !== clen - 1 && i !== clen - 2) {
          this.dateList.push(
            this.formatDate(i == 0 ? date : this.addDate(date, 1))
          );
        }
      }
    },
    addDate(date, n) {
      date.setDate(date.getDate() + n);
      return date;
    },
    formatDate(date) {
      let year = date.getFullYear() + "-";
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month + "-";
      } else {
        month = month + "-";
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return year + month + day;
    },
    //点击上一周日期
    clickLastWeek() {
      this.dateList = [];
      this.setDate(this.addDate(this.currentFirstDate, -7));
    },
    //点击下一周日期
    clickNextWeek() {
      this.dateList = [];
      this.setDate(this.addDate(this.currentFirstDate, 7));
    },
    downFile() {
      const that = this;
      let paramas = new FormData();
      paramas.append("name", this.queryTeacherName);
      if (this.queryTime[0]) {
        paramas.append("start_time", this.queryTime[0]);
        paramas.append("end_time", this.queryTime[1]);
      }
      this.$postFile("/base/exportDaiSchedule.do", paramas)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.picker {
  margin-left: 10px;
}

.teacher-header {
  border: 1px solid #000;
  background-color: #acd5e9;
  width: 558px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: 20px;
}
.teacher {
  display: flex;
  flex-wrap: wrap;
}
.teacher-name {
  width: 100px;
  border: 1px solid #000;
  line-height: 30px;
  text-align: center;
  height: 30px;
  padding: 5px;
  cursor: pointer;
}

.teacher-name:focus {
  background-color: #acd5e9;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.header-button {
  width: 190px;
}
.header-query {
  margin: 0 10px;
}
.refresh-question {
  margin-left: 20px;
}
.class-selector {
  margin: 20px 0 20px 250px;
}
.table-box {
  margin: 0 auto;
}
.substitute-content {
  height: 600px;
  overflow-x: auto;
}
.select-teacher {
  margin-top: 20px;
  margin-left: 80px;
}
</style>
